import activeBoost from "../../assets/icons/rocket1.png";
import inActiveBoost from "../../assets/icons/rocket2.png";
import availableJoker from "../../assets/icons/joker1.png";
import unAavailableJoker from "../../assets/icons/joker2.png";
import InputScoreSelectScore from "../score/InputScoreSelectScore";
import "../icons.scss";

import { convertShootToScore } from "../../js/scoring";

import { useState } from "react";

function PlayerInputScore({
  currentHoleNumber,
  holeNumberChanged,
  player,
  setHoleNumberChangedFlag,
  updatePlayerScore,
  numberOfHoles,
}) {
  const [enteredScore, setEnteredScore] = useState(null);
  const [scoreChanged, setScoreChanged] = useState(null);

  const resetScore = (playerNumber) => {
    setEnteredScore(0);
    updatePlayerScore(playerNumber, 0);
    setScoreChanged(false);
  };

  const selectScore = (playerNumber, score) => {
    setEnteredScore(score);
    setScoreChanged(true);
    setHoleNumberChangedFlag(false);
    updatePlayerScore(playerNumber, score);
  };

  const playerScoreForHole = player.holesAndScores.filter(
    (p) => p.holeNumber === currentHoleNumber
  );
  const jokerUsed =
    playerScoreForHole &&
    playerScoreForHole.length > 0 &&
    playerScoreForHole[0].jokerUsed;

  const alreadyEnteredScore =
    playerScoreForHole &&
    playerScoreForHole.length > 0 &&
    playerScoreForHole[0].enteredScoreForHole > 0;

  //In the event we switch holes, we need to make sure to reset the score value that appears to either
  //the correctly inputted score value for that hole (if it was already inputted from a previous hole)
  //or to a 0 value (if we are going to a new hole). If we aren't switching holes and have instead selected a new score,
  //then display that entered score value
  let displayedScore =
    alreadyEnteredScore && (holeNumberChanged || !scoreChanged)
      ? playerScoreForHole[0].enteredScoreForHole
      : scoreChanged
      ? enteredScore
      : 0;

  const holesArray = Array.from(
    { length: numberOfHoles },
    (_, i) => i + 1
  );

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-5 pl-3">{player.playerName}</div>
          <div className="col-2">
            {holesArray.map((h) => {
              return (
                <InputScoreSelectScore
                  playerNumber={player.playerNumber}
                  playerScoreForHole={playerScoreForHole}
                  resetScore={resetScore}
                  setSelectScore={selectScore}
                  visible={h === currentHoleNumber}
                  holeNumberChanged={holeNumberChanged}
                />
              );
            })}
          </div>
          <div className="col-2">
            {convertShootToScore(
              displayedScore,
              jokerUsed,
              player.boostScoring
            )}
          </div>
          <div className="col-1">
            <img
              className="icon"
              src={player.boostScoring ? activeBoost : inActiveBoost}
              alt=""
            />
          </div>
          <div className="col-1">
            <img
              className="icon"
              src={jokerUsed ? availableJoker : unAavailableJoker}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerInputScore;
