// components
import Button from "../elements/Button";
import FormGroup from "../elements/FormGroup";
import FormInput from "../elements/FormInput";

// js
import { ButtonStyle, InputType } from "../../js/enums";
import { centreNames } from "../../js/scoring";

function LoginForm({
  displayModal,
  formik,
  setSelectCentre,
  prefilledInBookingRef,
  preSelectedCentre,
  centreCodeLoginFailed,
}) {
  const changeSelection = (value) => {
    setSelectCentre(value.target.value);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      {!prefilledInBookingRef && (
        <div className={"pb-3"}>
          <label htmlFor={"SelectCentre"}>Centre</label>
          <select
            name={"SelectCentre"}
            id={"SelectCentre"}
            className={"form-control"}
            onChange={(value) => changeSelection(value)}
          >
            <option value="" disabled selected>
              Select your centre
            </option>
            {centreNames.map((c) => {
              return (
                <option
                  value={c.org}
                  selected={preSelectedCentre === c.org}
                >
                  {c.Label}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <FormGroup>
        <FormInput
          id="gameCode"
          name="gameCode"
          label="Game code"
          onChange={formik.handleChange}
          type={InputType.Text}
          value={formik.values.gameCode}
          className={"form-control"}
          inputMask={"***"} //TODO: In the future change this to "aaa"
          showInputMask={false}
          maskChar={""}
        />
      </FormGroup>
      <Button
        style={ButtonStyle.Link}
        className="btn-block text-right pb-3"
        text={"Where is my game code?"}
        onClick={() => displayModal(true)}
        type={InputType.Button}
      />
      {(prefilledInBookingRef || centreCodeLoginFailed) && (
        <FormGroup>
          <FormInput
            id="bookingReference"
            name="bookingReference"
            label="Booking reference"
            onChange={formik.handleChange}
            type={InputType.Text}
            value={formik.values.bookingReference}
            className={"form-control"}
          />
        </FormGroup>
      )}

      <Button
        style={ButtonStyle.Primary}
        disabled={formik.isSubmitting}
        isBlock={true}
        text="Continue"
        type={InputType.Submit}
      />
    </form>
  );
}

export default LoginForm;
