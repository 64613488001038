// components
import Button from "../elements/Button";
import Modal from "../elements/Modal";

// js
import { ButtonStyle } from "../../js/enums";

function LoginHelpModal({ displayModal }) {
  return (
    <Modal
      positionRelative={false}
      small={true}
      showBackdrop={true}
      className="modal-lg"
    >
      <div className="modal-body text-center">
        <small>{"Your code can be found on the receipt"}</small>
      </div>
      <div className="modal-footer">
        <Button
          style={ButtonStyle.Secondary}
          isBlock={true}
          onClick={() => displayModal(false)}
          text={"Close"}
        />
      </div>
    </Modal>
  );
}

export default LoginHelpModal;
