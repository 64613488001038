// elements
import BackButton from "../elements/BackButton";
import RoundOverview from "./RoundOverview";
// js
import { DispatchMethods } from "../../js/enums";

function SelectRound({ game }) {
  const {
    allowScoreEntry,
    bookingRef,
    centre,
    groupNameAndCode,
    groupsAndGames,
  } = game;

  let gameStillLeftToPlay = false;
  groupsAndGames.forEach((game) => {
    if (game.gameStatus !== 3) {
      gameStillLeftToPlay = true;
    }
  });

  return (
    <>
      <BackButton
        dispatchMethod={DispatchMethods.Logout}
        text={"Logout"}
      />
      <div className="container text-center">
        {bookingRef && <h5>Booking reference: {bookingRef}</h5>}
        {groupNameAndCode && <h5>Game code: {groupNameAndCode}</h5>}
        {allowScoreEntry && gameStillLeftToPlay && (
          <p>Select a game to input scores into</p>
        )}
      </div>
      <hr />
      {groupsAndGames.map((g, index) => (
        <RoundOverview
          key={index}
          game={g}
          allowScoreEntry={allowScoreEntry}
          previousRound={index > 0 ? groupsAndGames[index - 1] : null}
          centre={centre}
        />
      ))}
    </>
  );
}

export default SelectRound;
