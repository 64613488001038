const AlertStyle = Object.freeze({
  Primary: "alert-primary",
  Secondary: "alert-secondary",
  Success: "alert-success",
  Danger: "alert-danger",
  Warning: "alert-warning",
  Info: "alert-info",
  Light: "alert-light",
  Dark: "alert-dark",
});

const ButtonStyle = Object.freeze({
  Primary: "btn-primary",
  Secondary: "btn-secondary",
  Success: "btn-success",
  Danger: "btn-danger",
  Warning: "btn-warning",
  Info: "btn-info",
  Light: "btn-light",
  Dark: "btn-dark",
  Link: "btn-link",
});

const DispatchMethods = Object.freeze({
  BackToLeaderboard: "Back_To_Leaderboard",
  BackToSelectGame: "Back_To_Select_Game",
  ContinueGame: "Continue_Game",
  InputScores: "Input_Scores",
  Logout: "Logout",
  RefreshScores: "Refresh_Scores",
  SaveScores: "Save_Scores",
  SelectCourse: "Select_Course",
  SelectGame: "Select_Game",
  StartGame: "Start_Game",
  UpdatePlayerDetails: "Update_Player_Details",
});

const InputType = Object.freeze({
  Button: "button",
  Checkbox: "checkbox",
  Radio: "radio",
  Submit: "submit",
  Text: "text",
});

export { AlertStyle, ButtonStyle, DispatchMethods, InputType };
