import { useState } from "react";
import { useFormik } from "formik";

// elements
import Alert from "../elements/Alert";
import Header from "../header/Header";
import LoginForm from "./LoginForm";
import LoginHelpModal from "./LoginHelpModal";

// js
import { examineQueryParams, getCentreName } from "../../js/scoring";
import { DispatchMethods } from "../../js/enums";
import {
  LoginByCodeAndBookingRef,
  LoginByCodeAndCentre,
} from "../../js/scoringService";

// reducer
import { useGameReducer } from "../../GameContext";

function Login({ game }) {
  const dispatch = useGameReducer();

  const allowAutoLogin = !(game && game.NoAutoLogin);

  const [autoLoginAttemped, setAutoLoginAttemptedFlag] =
    useState(null);
  const [centreCodeLoginFailed, setCentreCodeLoginFailedFlag] =
    useState(null);
  const [selectedCentre, setSelectedCentre] = useState(null);
  const [initialCentreSetFlag, setInitialCentreSetFlag] =
    useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);

  const displayModal = (toggle) => {
    setModal(toggle);
    setErrorMessage("");
  };

  const params = new Proxy(
    new URLSearchParams(window.location.search),
    {
      get: (searchParams, prop) =>
        examineQueryParams(searchParams.get(prop), prop),
    }
  );

  //Don't prefill in values for code and booking ref if we have just logged out from another game
  const initialValues = {
    gameCode:
      allowAutoLogin && params.code !== null && params.code !== ""
        ? params.code
        : "",
    bookingReference:
      allowAutoLogin &&
      params.bookingref !== null &&
      params.bookingref !== ""
        ? params.bookingref
        : "",
  };

  const loginAPICall = (code, bookingRef, centre, actions) => {
    //In testing out the different query param combinations, need to add these or checks
    //to allow the site to work with both valid details in the URL and invalid details in the URL
    //and for when successfully logging in with valid details and then logging out.
    if (
      bookingRef !== null &&
      bookingRef !== "" &&
      (prefilledInBookingRef ||
        centreCodeLoginFailed ||
        autoLoginAttemped ||
        actions)
    ) {
      return LoginByCodeAndBookingRef(code, bookingRef);
    } else {
      return LoginByCodeAndCentre(code, centre);
    }
  };

  const login = (code, bookingRef, centre, actions) => {
    loginAPICall(code, bookingRef, centre, actions)
      .then((response) => {
        //If an empty field was given, then the API call will return a response.response.data rather
        //than a response.data. We have to check whether this is the case
        let res;
        if (response.response) {
          res = response.response;
        } else {
          res = response;
        }
        const data = res.data;
        if (data.statusCode !== null && data.statusCode === 0) {
          dispatch({
            type: DispatchMethods.SelectGame,
            game: data.result,
          });
        } else {
          if (actions) {
            actions.setSubmitting(false);
          } else {
            setAutoLoginAttemptedFlag(true);
          }
          setErrorMessage(data.message);
          //This occurs if trying to login with code and centre and it fails. Then it will prompt the user
          //to login using the booking ref
          if (data.statusCode !== null && data.statusCode === 504) {
            setCentreCodeLoginFailedFlag(true);
          }
        }
      })
      .catch((err) => {
        if (actions) {
          actions.setSubmitting(false);
          setErrorMessage("Something went wrong");
        } else {
          setAutoLoginAttemptedFlag(true);
        }
      });
  };

  const onSubmit = (values, actions, setErrorMessage) => {
    login(
      values.gameCode,
      values.bookingReference,
      selectedCentre,
      actions
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, actions) =>
      onSubmit(values, actions, setErrorMessage),
  });

  const prefilledInBookingRef =
    allowAutoLogin &&
    params.bookingref !== null &&
    params.bookingref !== "";

  const selectCentre = (centre) => {
    setSelectedCentre(centre);
  };

  const preSelectedCentre =
    allowAutoLogin &&
    params.centre !== null &&
    params.centre !== "" &&
    getCentreName(params.centre) !== ""
      ? params.centre.toLowerCase()
      : "";

  if (!initialCentreSetFlag) {
    if (preSelectedCentre !== "") {
      selectCentre(params.centre);
    }
    setInitialCentreSetFlag(true);
  }
  if (
    allowAutoLogin &&
    !autoLoginAttemped &&
    params.code !== null &&
    params.code !== "" &&
    (prefilledInBookingRef || preSelectedCentre !== "")
  ) {
    //Auto login only occurs if loading up site first time with
    //code and booking ref or centre given as query params
    login(params.code, params.bookingref, params.centre);
  } else {
    return (
      <>
        <Header />
        <div className="container">
          <LoginForm
            displayModal={displayModal}
            formik={formik}
            setSelectCentre={selectCentre}
            prefilledInBookingRef={
              prefilledInBookingRef && allowAutoLogin
            }
            preSelectedCentre={preSelectedCentre}
            centreCodeLoginFailed={centreCodeLoginFailed}
          />
          {errorMessage && <Alert text={errorMessage} />}
        </div>
        {modal && <LoginHelpModal displayModal={displayModal} />}
      </>
    );
  }
}

export default Login;
