import PropTypes from "prop-types";

// js
import { classNames } from "../../js/classNames";
import { ButtonStyle } from "../../js/enums";

function Button({
  className,
  disabled,
  isBlock,
  onClick,
  style,
  text,
  type,
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(
        "btn",
        style,
        isBlock ? "btn-block" : "",
        className
      )}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  isBlock: false,
  style: ButtonStyle.Info,
};

export default Button;
