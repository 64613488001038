import PropTypes from "prop-types";

// js
import { classNames } from "../../js/classNames";
import { AlertStyle } from "../../js/enums";

function Alert({ className, style, title, text }) {
  return (
    <div
      className={classNames("alert", style, className)}
      role="alert"
    >
      {title && (
        <p>
          <strong>{title}</strong>
        </p>
      )}
      <p className="mb-0">{text}</p>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

Alert.defaultProps = {
  className: "my-3",
  style: AlertStyle.Danger,
};

export default Alert;
