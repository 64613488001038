import Axios from "axios";

const API = process.env.REACT_APP_API || "api";

function Post(method, data) {
  return Axios.post(API + "/" + method, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

export const LoginByCodeAndBookingRef = (code, bookingRef) => {
  return Post("LoginByCodeAndBookingRef", {
    Code: code,
    BookingRef: bookingRef,
  });
};

export const LoginByCodeAndCentre = (code, centre) => {
  return Post("LoginByCodeAndCentre", {
    Code: code,
    Centre: centre,
  });
};

export const StartGame = (
  gameItemId,
  code,
  bookingRef,
  course,
  centre
) => {
  return Post("StartGame", {
    GameItemId: gameItemId,
    Code: code,
    BookingRef: bookingRef,
    Course: course,
    Centre: centre,
  });
};

export const UpdatePlayer = (
  gameId,
  gameItemId,
  playerId,
  playerName,
  playerNumber,
  boostScoring
) => {
  return Post("UpdatePlayerInfo", {
    GameId: gameId,
    GameItemId: gameItemId,
    PlayerId: playerId,
    PlayerName: playerName,
    PlayerNumber: playerNumber,
    BoostScoring: boostScoring,
  });
};

export const SaveScoresAndReturnUpdatedGame = (
  gameId,
  gameItemId,
  currentHoleNumber,
  submittedSelectedScoreList
) => {
  return Post("SaveScoresAndReturnUpdatedGame", {
    GameId: gameId,
    GameItemId: gameItemId,
    CurrentHoleNumber: currentHoleNumber,
    Player1Score: submittedSelectedScoreList[1]
      ? submittedSelectedScoreList[1]
      : 0,
    Player2Score: submittedSelectedScoreList[2]
      ? submittedSelectedScoreList[2]
      : 0,
    Player3Score: submittedSelectedScoreList[3]
      ? submittedSelectedScoreList[3]
      : 0,
    Player4Score: submittedSelectedScoreList[4]
      ? submittedSelectedScoreList[4]
      : 0,
    Player5Score: submittedSelectedScoreList[5]
      ? submittedSelectedScoreList[5]
      : 0,
    Player6Score: submittedSelectedScoreList[6]
      ? submittedSelectedScoreList[6]
      : 0,
  });
};

export const ApplyJokersAndReturnUpdatedGame = (
  gameId,
  gameItemId,
  holeNumber,
  applyJokerList
) => {
  return Post("ApplyJokersAndReturnUpdatedGame", {
    GameId: gameId,
    GameItemId: gameItemId,
    HoleNumber: holeNumber,
    Player1Joker: applyJokerList[1] ? applyJokerList[1] : false,
    Player2Joker: applyJokerList[2] ? applyJokerList[2] : false,
    Player3Joker: applyJokerList[3] ? applyJokerList[3] : false,
    Player4Joker: applyJokerList[4] ? applyJokerList[4] : false,
    Player5Joker: applyJokerList[5] ? applyJokerList[5] : false,
    Player6Joker: applyJokerList[6] ? applyJokerList[6] : false,
  });
};
