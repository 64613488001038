import { classNames } from "../../js/classNames";

function FormGroup({ children, className }) {
  return (
    <div className={classNames("form-group", className)}>
      {children}
    </div>
  );
}

export default FormGroup;
