// elements
import Button from "../elements/Button";

// js
import { ButtonStyle } from "../../js/enums";
import {
  getGameStatusText,
  selectRound,
  shouldDisableSelectRound,
} from "../../js/scoring";

// reducer
import { useGameReducer } from "../../GameContext";

function SelectRoundButton({ game, previousRound, allowScoreEntry }) {
  const { gameItemId, gameNumber, gameStatus } = game;
  const dispatch = useGameReducer();
  let showRoundButton = allowScoreEntry || gameStatus !== 1;
  return (
    showRoundButton && (
      <Button
        isBlock={true}
        style={
          allowScoreEntry && gameStatus !== 3
            ? ButtonStyle.Primary
            : ButtonStyle.Link
        }
        text={getGameStatusText(gameStatus, allowScoreEntry)}
        onClick={() => selectRound(dispatch, gameItemId, gameStatus)}
        disabled={shouldDisableSelectRound(
          previousRound,
          gameNumber,
          gameStatus,
          allowScoreEntry
        )}
      />
    )
  );
}

export default SelectRoundButton;
