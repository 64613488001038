import { DispatchMethods } from "./js/enums";
import {
  checkIfNextHoleHasScoresAlreadyInput,
  checkForAvailableJokers,
  getSelectedGame,
} from "./js/scoring";

const gameStateReducer = (state, action) => {
  switch (action.type) {
    case DispatchMethods.ApplyJokers:
      return applyJokers(state, action);
    case DispatchMethods.BackToLeaderboard:
      return backToLeaderboard(state);
    case DispatchMethods.BackToSelectGame:
      return backToSelectGame(state);
    case DispatchMethods.ContinueGame:
      return continueGame(state, action);
    case DispatchMethods.InputScores:
      return inputScores(state, action);
    case DispatchMethods.Logout:
      return logout();
    case DispatchMethods.RefreshScores:
      return refreshScores(state, action);
    case DispatchMethods.SaveScores:
      return saveScores(state, action);
    case DispatchMethods.SelectCourse:
      return selectCourse(state, action);
    case DispatchMethods.SelectGame:
      return selectGame(state, action);
    case DispatchMethods.StartGame:
      return startGame(state, action);
    default:
      return state;
  }
};

const applyJokers = (state, action) => {
  let updatedState = action.game;
  updatedState.selectedGameItemId = state.selectedGameItemId;
  updatedState.inputScores = false;
  updatedState.applyJokers = false;
  updatedState.nextHole = 0;
  return updatedState;
};

const backToLeaderboard = (state) => {
  return {
    ...state,
    applyJokers: false,
    inputScores: false,
    nextHole: 0,
  };
};

const backToSelectGame = (state) => {
  return { ...state, selectCourse: null, selectedGameItemId: null };
};

const continueGame = (state, action) => {
  return { ...state, selectedGameItemId: action.gameItemId };
};

const inputScores = (state, action) => {
  let updatedState = action.game;
  updatedState.selectedGameItemId = state.selectedGameItemId;
  updatedState.inputScores = true;
  return updatedState;
};

const logout = () => {
  let updatedState = {};
  updatedState.NoAutoLogin = true;
  return updatedState;
};

const refreshScores = (state, action) => {
  let updatedState = action.game;
  updatedState.selectedGameItemId = state.selectedGameItemId;
  return updatedState;
};

const saveScores = (state, action) => {
  let updatedState = action.game;
  updatedState.selectedGameItemId = state.selectedGameItemId;
  updatedState.inputScores = false;

  //Check if we need to go onto the jokers screen. We do this by checking whether the next hole is past the number of holes for this course
  //or if there are no jokers available across all players for the selected game or if the next hole already has scores inputted.
  //If not then go to the leaderboards screen.
  const selectedGame = getSelectedGame(action.game);
  if (
    action.nextHole < selectedGame.numberOfHoles + 1 &&
    checkForAvailableJokers(selectedGame) &&
    !checkIfNextHoleHasScoresAlreadyInput(
      action.nextHole,
      selectedGame
    )
  ) {
    updatedState.applyJokers = true;
    updatedState.nextHole = action.nextHole;
  }
  return updatedState;
};

const selectCourse = (state, action) => {
  return {
    ...state,
    selectCourse: true,
    selectedGameItemId: action.gameItemId,
  };
};

const selectGame = (state, action) => {
  return action.game;
};

const startGame = (state, action) => {
  let updatedState = { ...state };
  updatedState.groupsAndGames.forEach((game) => {
    if (game.gameItemId === action.gameItemId) {
      game.gameStarted = action.gameStarted;
      game.gameStatus = 2;
      if (action.course) {
        game.course = action.course;
      }
      if (action.numberOfHole && action.numberOfHole > 0) {
        game.numberOfHoles = action.numberOfHoles;
      } else {
        game.numberOfHoles = 9;
      }
    }
  });
  updatedState.selectedGameItemId = action.gameItemId;
  updatedState.selectCourse = false;
  return updatedState;
};

export { gameStateReducer };
