// elements
import FormInput from "../elements/FormInput";

// js
import { InputType } from "../../js/enums";

function Course({ course, label, onChange }) {
  return (
    <>
      <div className="container">
        <FormInput
          id={course}
          name="Course"
          label={label}
          type={InputType.Radio}
          value={course}
          className="custom-radio cursor-pointer float-right"
          onChange={() => onChange()}
        />
      </div>
      <hr />
    </>
  );
}

export default Course;
