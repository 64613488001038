//icons
import boostIcon from "../../assets/icons/rocket1.png";
import jokerIcon from "../../assets/icons/joker1.png";

// components
import Button from "../elements/Button";
import Modal from "../elements/Modal";

// js
import { ButtonStyle } from "../../js/enums";

function LeaderboardHelpModal({ displayModal }) {
  return (
    <Modal
      positionRelative={false}
      small={true}
      showBackdrop={true}
      className="modal-lg"
    >
      <div className="modal-body text-center">
        <h2>Boost</h2>
        <small>{"Increase your score for every hole"}</small>
        <br />
        <img className="icon" src={boostIcon} alt="" />
        <small>{" = Points x 1.5"}</small>
        <hr />
        <h2>Joker</h2>
        <small>{"Every player gets two jokers per game"}</small>
        <br />
        <img className="icon" src={jokerIcon} alt="" />
        <small>{" = Points x 2"}</small>
      </div>
      <div className="modal-footer">
        <Button
          style={ButtonStyle.Secondary}
          isBlock={true}
          onClick={() => displayModal(false)}
          text={"Close"}
        />
      </div>
    </Modal>
  );
}

export default LeaderboardHelpModal;
