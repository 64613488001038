import "./_Header.scss";

// assets
import logo from "../../assets/icons/logo.svg";

function Header() {
  return (
    <nav className="d-flex justify-content-center bg-primary p-3 mb-3">
      <img src={logo} className="logo" alt="Puttstars" />
    </nav>
  );
}

export default Header;
