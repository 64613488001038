import { DispatchMethods } from "./enums";

const regCourses = [
  { Name: "a", Label: "The Rookie", Sort: 1 },
  { Name: "b", Label: "The Enthusiast", Sort: 2 },
  { Name: "c", Label: "The Master", Sort: 3 },
];

const twoCourses = [
  { Name: "a", Label: "The Rookie", Sort: 1 },
  { Name: "c", Label: "The Master", Sort: 3 },
];

const leedshwCourses = [{ Name: "a", Label: "The Rookie", Sort: 1 }];

const stocktonCourses = [{ Name: "a", Label: "The Rookie", Sort: 1 }];

const centreNames = [
  { org: "psleeds", Label: "Leeds", Courses: twoCourses },
  {
    org: "leedshw",
    Label: "Leeds (Hollywood Bowl)",
    Courses: leedshwCourses,
  },
  { org: "psharrow", Label: "Harrow", Courses: twoCourses },
  { org: "psyork", Label: "York", Courses: regCourses },
  { org: "psrochdale", Label: "Rochdale", Courses: regCourses },
  {
    org: "pspeterborough",
    Label: "Peterborough",
    Courses: regCourses,
  },
  {
    org: "stockton",
    Label: "Stockton (Hollywood Bowl)",
    Courses: stocktonCourses
  },
  {
    org: "colchester",
    Label: "Colchester (Hollywood Bowl)",
    Courses: twoCourses
  }
];

const jokerMultiplier = 2;
const boostMultiplier = 1.5;
const shotConversion = (shoots) => {
  switch (shoots) {
    case 0:
      return 0;
    case 1:
      return 1000;
    case 2:
      return 600;
    case 3:
      return 400;
    case 4:
      return 250;
    case 5:
      return 150;
    case 6:
      return 100;
    default:
      return 0;
  }
};

const getCanStartNewRound = (previousRound) => {
  return previousRound &&
    previousRound.gameStatus !== 1 &&
    previousRound.latestHole > previousRound.numberOfHoles / 2
    ? true
    : false;
};

const getCourseAndHoleName = (
  allowScoreEntry,
  centre,
  courseType,
  hole,
  gameStatus,
  numberOfHoles
) => {
  if (gameStatus === 1 && !allowScoreEntry) {
    return "Not started";
  }
  const holeName = getHoleName(hole, numberOfHoles);
  if (holeName) {
    return `${getCourseName(centre, courseType)} - ${holeName}`;
  }
  return getCourseName(centre, courseType);
};

const getCentreName = (centre) => {
  let res = centreNames.filter((c) => c.org === centre.toLowerCase());
  if (res.length === 1) {
    return res[0].Label;
  } else {
    return "";
  }
};

const getCentreCourses = (centre) => {
  let res = centreNames.filter((c) => c.org === centre.toLowerCase());
  if (res.length === 1) {
    return res[0].Courses.sort((a, b) => a.Sort - b.Sort);
  } else {
    return [];
  }
};

const getCourseName = (centre, courseType) => {
  if (!courseType) {
    return "";
  }

  let courseList = getCentreCourses(centre);
  if (courseList === []) {
    return courseType;
  }

  let res = courseList.filter(
    (c) => c.Name === courseType.toLowerCase()
  );

  if (res.length === 1) {
    return res[0].Label;
  } else {
    return courseType;
  }
};

const getGameStatusText = (gameStatus, allowScoreEntry) => {
  //Game is either finished or its a game in the past that has at least been started where we don't
  //want to allow the user to edit the scores but at least view them
  if (gameStatus === 3 || (gameStatus === 2 && !allowScoreEntry)) {
    return "View Scores";
  }

  return gameStatus === 1 ? "START" : "CONTINUE";
};

const getHoleName = (hole, numberOfHoles) => {
  return hole !== 0 && hole !== numberOfHoles ? `Hole ${hole}` : null;
};

const getRoundName = (gameNumber) => {
  return `Round ${gameNumber}`;
};

const logout = (dispatch) =>
  dispatch({
    type: DispatchMethods.Logout,
  });

const selectRound = (dispatch, gameItemId, gameStatus) => {
  if (gameStatus === 1) {
    dispatch({
      type: DispatchMethods.SelectCourse,
      gameItemId: gameItemId,
    });
  } else {
    dispatch({
      type: DispatchMethods.ContinueGame,
      gameItemId: gameItemId,
    });
  }
};

const shouldDisableSelectRound = (previousRound, gameNumber) => {
  const canStartNewRound = getCanStartNewRound(previousRound);
  return gameNumber > 1 && !canStartNewRound;
};

const convertShootToScore = (shoots, jokerUsed, boostScoring) => {
  var res = 0;

  res = shotConversion(shoots);
  if (jokerUsed != null && jokerUsed) {
    res = Math.ceil(res * jokerMultiplier);
  }
  if (boostScoring != null && boostScoring) {
    res = Math.ceil(res * boostMultiplier);
  }
  return res;
};

const getSelectedGame = (game) => {
  return game.groupsAndGames.filter(
    (g) => g.gameItemId === game.selectedGameItemId
  )[0];
};

const checkForAvailableJokers = (game) => {
  return (
    game.playersAndScores.filter((p) => p.jokersRemaining > 0)
      .length > 0
  );
};

const checkIfNextHoleHasScoresAlreadyInput = (nextHole, game) => {
  return (
    game.playersAndScores.filter(
      (p) =>
        p.holesAndScores.filter(
          (h) =>
            h.holeNumber === nextHole && h.enteredScoreForHole > 0
        ).length > 0
    ).length > 0
  );
};

//Only display use them or lose them message on jokers screen if:
//we are applying jokers for hole 8 and there is at least one player with 2 jokers
//OR
//we are applying jokers for hole 9 and there is at least one player with a joker remaining
const displayJokerUseThemOrLoseThemMessage = (game, holeNumber) => {
  if (holeNumber < game.numberOfHoles - 1) {
    return false;
  }

  if (holeNumber === game.numberOfHoles - 1) {
    return (
      game.playersAndScores.filter((p) => p.jokersRemaining === 2)
        .length > 0
    );
  } else {
    return (
      game.playersAndScores.filter((p) => p.jokersRemaining > 0)
        .length > 0
    );
  }
};

const displayJokerUseThemOrLoseThemHighlightForSpecificPlayer = (
  game,
  holeNumber,
  player
) => {
  if (holeNumber < game.numberOfHoles - 1) {
    return false;
  }

  if (holeNumber === game.numberOfHoles - 1) {
    return (
      game.playersAndScores.filter(
        (p) =>
          p.jokersRemaining === 2 && p.playerId === player.playerId
      ).length > 0
    );
  } else {
    return (
      game.playersAndScores.filter(
        (p) => p.jokersRemaining > 0 && p.playerId === player.playerId
      ).length > 0
    );
  }
};

const examineQueryParams = (value, paramName) => {
  const regex = /^[a-z0-9-]+$/i;

  //Check bookingref in query param doesn't exceed 10 characters and only contains alphanumeric and - characters
  if (
    paramName === "bookingref" &&
    (value === null ||
      value.length > 17 ||
      value.match(regex) === null)
  ) {
    return "";
  }
  return value;
};

export {
  centreNames,
  checkIfNextHoleHasScoresAlreadyInput,
  checkForAvailableJokers,
  convertShootToScore,
  displayJokerUseThemOrLoseThemMessage,
  displayJokerUseThemOrLoseThemHighlightForSpecificPlayer,
  examineQueryParams,
  getCentreName,
  getCentreCourses,
  getCourseAndHoleName,
  getCourseName,
  getGameStatusText,
  getRoundName,
  getSelectedGame,
  logout,
  selectRound,
  shouldDisableSelectRound,
};
