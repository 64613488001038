// elements
import BackButton from "../elements/BackButton";
import CourseForm from "./CourseForm";
// js
import { DispatchMethods } from "../../js/enums";

function SelectCourse({ game }) {
  return (
    <>
      <BackButton
        dispatchMethod={DispatchMethods.BackToSelectGame}
        text={"Back"}
      />
      <h5 className="text-center">Select course</h5>
      <CourseForm game={game} />
    </>
  );
}

export default SelectCourse;
