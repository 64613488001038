import React, { createContext, useContext, useReducer } from "react";
import { gameStateReducer } from "./GameReducer";

export const GameContext = createContext();

export function useGameState() {
  return useContext(GameContext)[0];
}

export function useGameReducer() {
  return useContext(GameContext)[1];
}

export function GameStateProvider({ children }) {
  let initialState = {};

  const value = useReducer(gameStateReducer, initialState);

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
}
