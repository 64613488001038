import { useFormik } from "formik";

// components
import Button from "../elements/Button";
import FormGroup from "../elements/FormGroup";
import FormInput from "../elements/FormInput";
import Modal from "../elements/Modal";

// js
import {
  ButtonStyle,
  DispatchMethods,
  InputType,
} from "../../js/enums";
import { UpdatePlayer } from "../../js/scoringService";

import { useGameReducer } from "../../GameContext";

function PlayerEdit({
  closeModal,
  game,
  player,
  selectedGame,
  setErrorMessage,
}) {
  const dispatch = useGameReducer();

  const formik = useFormik({
    enableReinitialize: true,
    //player object cannot be null
    initialValues: {
      playerName: !player.playerName.startsWith("Edit Name")
        ? player.playerName
        : "",
      boostScoring: player.boostScoring,
      playerNumber: player.playerNumber,
    },
    onSubmit: (values, actions) => {
      UpdatePlayer(
        game.gameId,
        selectedGame.gameItemId,
        player.playerId,
        values.playerName,
        player.playerNumber,
        values.boostScoring
      )
        .then((response) => {
          const data = response.data;
          if (data.statusCode !== null && data.statusCode === 0) {
            dispatch({
              type: DispatchMethods.RefreshScores,
              game: data.result,
            });
            closeModal();
            actions.setSubmitting(false);
          } else {
            actions.setSubmitting(false);
            setErrorMessage(data.message);
          }
        })
        .catch((err) => {
          actions.setSubmitting(false);
          setErrorMessage("Something went wrong");
        });
    },
  });
  return (
    <div>
      <Modal
        positionRelative={false}
        small={true}
        showBackdrop={true}
        className="modal-lg"
        title={`Player ${formik.values.playerNumber}`}
      >
        <div className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <FormInput
                id="playerName"
                name="playerName"
                label="Edit name"
                onChange={formik.handleChange}
                type={InputType.Text}
                value={formik.values.playerName}
                className="form-control mb-3"
              />
              <FormInput
                id="boostScoring"
                name="boostScoring"
                label="Boost Scoring"
                onChange={formik.handleChange}
                type={InputType.Checkbox}
                className="custom-radio cursor-pointer float-right mx-3"
                checked={formik.values.boostScoring}
              />
            </FormGroup>
            <Button
              isBlock={true}
              style={ButtonStyle.Primary}
              text="Save"
              type={InputType.Submit}
              disabled={formik.isSubmitting}
            />
            <Button
              isBlock={true}
              style={ButtonStyle.Link}
              text={"Cancel"}
              onClick={() => closeModal()}
              type={InputType.Button}
              disabled={formik.isSubmitting}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default PlayerEdit;
