// elements
import Button from "../elements/Button";

// js
import { ButtonStyle, InputType } from "../../js/enums";

function InputScoreSelectHole({
  currentHoleNumber,
  setHoleNumber,
  numberOfHoles,
}) {
  return (
    <>
      <div className="row">
        <div className="col">
          <Button
            isBlock={true}
            style={ButtonStyle.Link}
            text={"Prev"}
            onClick={() => setHoleNumber(currentHoleNumber - 1)}
            type={InputType.Button}
            disabled={currentHoleNumber - 1 < 1}
          />
        </div>
        <div className="col">
          <h5 className="text-center">Hole {currentHoleNumber}</h5>
        </div>
        <div className="col">
          <Button
            isBlock={true}
            style={ButtonStyle.Link}
            text={"Next"}
            onClick={() => setHoleNumber(currentHoleNumber + 1)}
            type={InputType.Button}
            disabled={currentHoleNumber + 1 > numberOfHoles}
          />
        </div>
      </div>
    </>
  );
}

export default InputScoreSelectHole;
