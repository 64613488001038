// elements
import Course from "./Course";

// js
import { getCentreCourses } from "../../js/scoring";

function Courses({ centre, courseSelected }) {
  let courseList = getCentreCourses(centre);
  return (
    <>
      {courseList.map((course) => {
        return (
          <Course
            course={course.Name}
            label={course.Label}
            onChange={() => courseSelected(course.Name)}
          />
        );
      })}
    </>
  );
}

export default Courses;
