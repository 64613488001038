// elements
import Button from "./Button";

// js
import { ButtonStyle, InputType } from "../../js/enums";

// reducer
import { useGameReducer } from "../../GameContext";

function BackButton({ dispatchMethod, text }) {
  const dispatch = useGameReducer();
  return (
    <Button
      type={InputType.Button}
      style={ButtonStyle.Link}
      isBlock={true}
      className="text-left pb-3"
      text={text}
      onClick={() =>
        dispatch({
          type: dispatchMethod,
        })
      }
    />
  );
}

export default BackButton;
