import { useState } from "react";

// elements
import Alert from "../elements/Alert";
import ApplyJokerForm from "./ApplyJokerForm";
import ApplyJokerHelpModal from "./ApplyJokerHelpModal";
import BackButton from "../elements/BackButton";

// js
import { DispatchMethods } from "../../js/enums";
import { ApplyJokersAndReturnUpdatedGame } from "../../js/scoringService";

// reducer
import { useGameReducer } from "../../GameContext";

function ApplyJoker({ game }) {
  const dispatch = useGameReducer();

  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);

  const displayModal = (toggle) => {
    setModal(toggle);
  };

  const applyJokers = (applyJokerList) => {
    setIsSubmitting(true);
    if (applyJokerList === null) {
      dispatch({
        type: DispatchMethods.BackToLeaderboard,
      });
      setIsSubmitting(false);
    } else {
      ApplyJokersAndReturnUpdatedGame(
        game.gameId,
        game.selectedGameItemId,
        game.nextHole,
        applyJokerList
      )
        .then((response) => {
          const data = response.data;
          if (data.statusCode !== null && data.statusCode === 0) {
            dispatch({
              type: DispatchMethods.ApplyJokers,
              game: data.result,
            });
          } else {
            setErrorMessage(data.message);
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          setIsSubmitting(false);
        });
    }
  };
  return (
    <>
      <BackButton
        dispatchMethod={DispatchMethods.BackToLeaderboard}
        text={"Back"}
      />
      <ApplyJokerForm
        applyJokers={applyJokers}
        displayModal={displayModal}
        game={game}
        isSubmitting={isSubmitting}
      />
      {errorMessage && <Alert text={errorMessage} />}
      {modal && <ApplyJokerHelpModal displayModal={displayModal} />}
    </>
  );
}

export default ApplyJoker;
