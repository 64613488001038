import "../forms.scss";
import { classNames } from "../../js/classNames";
import InputMask from "react-input-mask";

function FormInput({
  checked,
  className,
  disabled,
  id,
  label,
  name,
  onChange,
  type,
  value,
  inputMask,
  showInputMask,
  maskChar,
}) {
  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <InputMask
        id={id}
        name={name}
        type={type}
        className={classNames(className)}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled}
        mask={inputMask}
        alwaysShowMask={showInputMask}
        maskChar={maskChar}
      />
    </div>
  );
}

export default FormInput;
