import { useGameState } from "../GameContext";
import { getSelectedGame } from "../js/scoring";
import Login from "./login/Login";
import SelectRound from "./round/SelectRound";
import Leaderboard from "./leaderboard/Leaderboard";
import SelectCourse from "./course/SelectCourse";
import InputScore from "./score/InputScore";
import ApplyJoker from "./joker/ApplyJoker";

function GameScore() {
  const game = useGameState();
  if (game && game.groupsAndGames && game.groupsAndGames.length) {
    if (game.selectCourse) {
      return <SelectCourse game={game} />;
    }
    const selectedGame = getSelectedGame(game);
    if (
      game.applyJokers &&
      game.nextHole > 0 &&
      game.nextHole < selectedGame.numberOfHoles + 1
    ) {
      return <ApplyJoker game={game} />;
    }
    if (game.inputScores) {
      return <InputScore game={game} />;
    }
    if (game.selectedGameItemId) {
      return <Leaderboard game={game} />;
    }
    return <SelectRound game={game} />;
  }
  return <Login game={game} />;
}

export default GameScore;
