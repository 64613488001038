// elements
import Alert from "../elements/Alert";
import Button from "../elements/Button";
import PlayerApplyJoker from "../player/PlayerApplyJoker";
// js
import { ButtonStyle, InputType } from "../../js/enums";
import {
  displayJokerUseThemOrLoseThemMessage,
  getSelectedGame,
} from "../../js/scoring";

// reducer
import { useState } from "react";

function ApplyJokerForm({
  applyJokers,
  displayModal,
  game,
  isSubmitting,
}) {
  const [modalFirstTimeDisplayed, setModalFirstTimeDisplayed] =
    useState(null);
  const [applyJokerList, setApplyJokerList] = useState(null);

  const selectedGame = getSelectedGame(game);

  if (
    !modalFirstTimeDisplayed &&
    selectedGame.gameNumber === 1 &&
    selectedGame.latestHole === 1
  ) {
    setModalFirstTimeDisplayed(true);
    displayModal(true);
  }

  const toggleJokerForPlayer = (player, toggle) => {
    let updatedJokerList = applyJokerList;

    //Initialise updatedJokerList if applyJokerList is null
    if (updatedJokerList === null) {
      updatedJokerList = {};
      let playerList = selectedGame.playersAndScores.sort(function (
        a,
        b
      ) {
        return a.playerNumber - b.playerNumber;
      });
      playerList.forEach((p) => {
        updatedJokerList[p.playerNumber] = false;
      });
    }

    updatedJokerList[player.playerNumber] = toggle;

    setApplyJokerList(updatedJokerList);
  };

  const submitJokers = () => {
    applyJokers(applyJokerList);
  };

  return (
    <>
      <div className="mb-2">
        <h5 className="container text-center">
          {`Play Joker on Hole ${game.nextHole}?`}
        </h5>
        <Button
          isBlock={true}
          style={ButtonStyle.Link}
          className="text-center"
          text={"What are jokers?"}
          onClick={() => displayModal(true)}
          type={InputType.Button}
        />
        <hr />
        {selectedGame.playersAndScores
          .sort(function (a, b) {
            return a.playerNumber - b.playerNumber;
          })
          .map((p, i) => {
            return (
              <>
                <PlayerApplyJoker
                  game={selectedGame}
                  holeNumber={game.nextHole}
                  player={p}
                  toggleJokerForPlayer={toggleJokerForPlayer}
                />
                <hr />
              </>
            );
          })}
        <div className="container">
          {displayJokerUseThemOrLoseThemMessage(
            selectedGame,
            game.nextHole
          ) && <Alert text={"Use them or lose them"} />}
          <div>
            <Button
              style={ButtonStyle.Primary}
              text={"Continue"}
              onClick={() => submitJokers()}
              type={InputType.Button}
              isBlock={true}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyJokerForm;
