function InputScoreSelectScore({
  playerNumber,
  playerScoreForHole,
  resetScore,
  setSelectScore,
  visible,
  holeNumberChanged,
}) {
  var selectedScore = 0;

  //Reset scoring states on hole switch
  if (holeNumberChanged) {
    resetScore(playerNumber);
  }

  //Pre load drop down list with already inputted scores for that hole
  if (
    playerScoreForHole.length > 0 &&
    playerScoreForHole[0].enteredScoreForHole &&
    playerScoreForHole[0].enteredScoreForHole > 0
  ) {
    selectedScore = playerScoreForHole[0].enteredScoreForHole;
  }

  const changeSelection = (value) => {
    var valueInt = parseInt(value.target.value);
    setSelectScore(playerNumber, valueInt);
  };

  const optionArray = [0, 1, 2, 3, 4, 5, 6];
  return (
    visible && (
      <>
        <select
          name={`Player${playerNumber}Score`}
          id={`Player${playerNumber}Score`}
          onChange={(value) => changeSelection(value)}
        >
          {optionArray.map((o) => {
            return (
              <option
                value={o}
                selected={selectedScore === o}
                hidden={o === 0}
              >
                {o}
              </option>
            );
          })}
        </select>
      </>
    )
  );
}

export default InputScoreSelectScore;
