import activeBoost from "../../assets/icons/rocket1.png";
import inActiveBoost from "../../assets/icons/rocket2.png";
import availableJoker from "../../assets/icons/joker1.png";
import unAavailableJoker from "../../assets/icons/joker2.png";
import "../icons.scss";
import pencil from "../../assets/icons/pencil.png";

function PlayerOverview({ editable, editName, player, position }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-1">
            <div className="circle">
              <p>{position}</p>
            </div>
          </div>
          <div
            className="col-6 pl-3"
            onClick={() => editable && editName()}
          >
            {player.playerName}
            {editable && (
              <img className="icon pl-1 pb-1" src={pencil} alt="" />
            )}
          </div>

          <div className="col-1 mr-2 pl-0">{player.totalScore}</div>
          <div className="col-1 pl-3">
            <img
              className="icon pb-1"
              src={player.boostScoring ? activeBoost : inActiveBoost}
              alt=""
            />
          </div>
          <div className="col-1 ">
            <img
              className="icon pb-1"
              src={
                player.jokersRemaining > 0
                  ? availableJoker
                  : unAavailableJoker
              }
              alt=""
            />
          </div>
          <div className="col-1">
            <img
              className="icon pb-1"
              src={
                player.jokersRemaining > 1
                  ? availableJoker
                  : unAavailableJoker
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerOverview;
