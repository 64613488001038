// js
import { getCourseAndHoleName, getRoundName } from "../../js/scoring";
import SelectRoundButton from "./SelectRoundButton";

function RoundOverview({
  game,
  allowScoreEntry,
  previousRound,
  centre,
}) {
  const {
    course,
    gameNumber,
    gameStatus,
    latestHole,
    numberOfHoles,
  } = game;
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <p>{getRoundName(gameNumber)}</p>
          </div>
          <div className="col text-right">
            <small>
              {getCourseAndHoleName(
                allowScoreEntry,
                centre,
                course,
                latestHole,
                gameStatus,
                numberOfHoles
              )}
            </small>
          </div>
        </div>
        <SelectRoundButton
          game={game}
          previousRound={previousRound}
          allowScoreEntry={allowScoreEntry}
        />
      </div>
      <hr />
    </>
  );
}

export default RoundOverview;
