//icons
import boostIcon from "../../assets/icons/rocket1.png";
import jokerIcon from "../../assets/icons/joker1.png";

// components
import Button from "../elements/Button";
import Modal from "../elements/Modal";

// js
import { ButtonStyle } from "../../js/enums";

function InputScoreHelpModal({ displayModal }) {
  return (
    <Modal positionRelative={false} showBackdrop={true}>
      <div className="modal-body text-center">
        <h4>Shots taken = Points earned</h4>
        <small>
          The fewer the putts, the higher the score. The player with
          the highest points at the end of the game wins!
        </small>
        <div className="row">
          <div className="col">
            <small>Hole in 1 = 1000 points</small>
          </div>
          <div className="col">
            <small>Hole in 4 = 250 points</small>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <small>Hole in 2 = 600 points</small>
          </div>
          <div className="col">
            <small>Hole in 5 = 150 points</small>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <small>Hole in 3 = 400 points</small>
          </div>
          <div className="col">
            <small>Hole in 6+ = 100 points</small>
          </div>
        </div>
        <hr />
        <strong>Boost</strong>
        <br />
        <img className="icon" src={boostIcon} alt="" />
        <small>{" = Points x 1.5"}</small>
        <hr />
        <strong>Joker</strong>
        <br />
        <img className="icon" src={jokerIcon} alt="" />
        <small>{" = Points x 2"}</small>
      </div>
      <div className="modal-footer">
        <Button
          style={ButtonStyle.Secondary}
          isBlock={true}
          onClick={() => displayModal(false)}
          text={"Close"}
        />
      </div>
    </Modal>
  );
}

export default InputScoreHelpModal;
