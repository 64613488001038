// elements
import Alert from "../elements/Alert";
import Button from "../elements/Button";
import PlayerEdit from "../player/PlayerEdit";
import PlayerOverview from "../player/PlayerOverview";

// js
import { ButtonStyle, InputType } from "../../js/enums";
import { getCourseName, getSelectedGame } from "../../js/scoring";

// reducer
import { useState } from "react";

function LeaderboardForm({
  displayModal,
  game,
  inputScores,
  isUpdatingScores,
  refreshScores,
}) {
  const [modalFirstTimeDisplayed, setModalFirstTimeDisplayed] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [player, setEditedPlayer] = useState(null);

  const selectedGame = getSelectedGame(game);

  if (
    !modalFirstTimeDisplayed &&
    selectedGame.gameNumber === 1 &&
    selectedGame.latestHole === 0
  ) {
    setModalFirstTimeDisplayed(true);
    displayModal(true);
  }

  const DisplayEditPlayerNameModal = (toggle, player) => {
    setModal(toggle);
    setErrorMessage("");
    setEditedPlayer(player);
  };

  let position = 0;
  let nextScore = -1;
  return (
    <>
      <div className="mb-2">
        <div className="container">
          <h5 className="text-center">
            Round {selectedGame.gameNumber} Leaderboard
          </h5>
          <h6 className="text-center">
            Course: {getCourseName(game.centre, selectedGame.course)}
          </h6>
          {selectedGame.gameStatus === 2 &&
            selectedGame.latestHole > 0 && (
              <h6 className="text-center">
                Scores after Hole {selectedGame.latestHole}
              </h6>
            )}
          <Button
            isBlock={true}
            style={ButtonStyle.Link}
            className="text-right ml-2"
            text={"What are these?"}
            onClick={() => displayModal(true)}
            type={InputType.Button}
          />
        </div>
        <hr />
        {selectedGame.playersAndScores
          .sort(function (a, b) {
            return b.totalScore - a.totalScore;
          })
          .map((p, i) => {
            //Get the position number of the player. If players are tied on total score then make sure they
            //are on the same position number
            if (nextScore === -1 || p.totalScore < nextScore) {
              position = i + 1;
              nextScore = p.totalScore;
            }
            return (
              <>
                <PlayerOverview
                  position={position}
                  player={p}
                  editName={() => DisplayEditPlayerNameModal(true, p)}
                  editable={
                    game.allowScoreEntry &&
                    selectedGame.gameStatus === 2
                  }
                />
                <hr />
              </>
            );
          })}
        <div className="container">
          {errorMessage && <Alert text={errorMessage} />}
          {game.allowScoreEntry && selectedGame.gameStatus === 2 && (
            <>
              <div>
                <Button
                  style={ButtonStyle.Link}
                  className="btn-block text-center font-weight-bold"
                  text={"Refresh scores"}
                  onClick={() => refreshScores()}
                  type={InputType.Button}
                  disabled={isUpdatingScores}
                />
              </div>
              <div>
                <Button
                  style={ButtonStyle.Primary}
                  text={"Add Scores"}
                  className="primary btn-block"
                  onClick={() => inputScores()}
                  type={InputType.Button}
                  disabled={isUpdatingScores}
                />
              </div>
            </>
          )}
        </div>
        {modal && player && (
          <PlayerEdit
            closeModal={() => DisplayEditPlayerNameModal(false, null)}
            game={game}
            player={player}
            selectedGame={selectedGame}
            setErrorMessage={() => setErrorMessage()}
          />
        )}
      </div>
    </>
  );
}

export default LeaderboardForm;
