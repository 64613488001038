//icons
import jokerIcon from "../../assets/icons/joker1.png";

// components
import Button from "../elements/Button";
import Modal from "../elements/Modal";

// js
import { ButtonStyle } from "../../js/enums";

function ApplyJokerHelpModal({ displayModal }) {
  return (
    <Modal positionRelative={false} showBackdrop={true}>
      <div className="modal-body text-center">
        <h2>What are Jokers?</h2>
        <small>
          Each player gets two jokers to play per game that doubles
          your points for that hole. To play your joker, select it
          before you play a hole. Make sure to use it or you’ll lose
          them and miss out on extra points!
        </small>
        <br />
        <div className="mt-3">
          <img className="icon" src={jokerIcon} alt="" />
          <small>{" = Points x 2"}</small>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          style={ButtonStyle.Secondary}
          isBlock={true}
          onClick={() => displayModal(false)}
          text={"Close"}
        />
      </div>
    </Modal>
  );
}

export default ApplyJokerHelpModal;
