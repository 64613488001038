import "./Modal.scss";

// js
import { classNames } from "../../js/classNames";

function Modal({
  children,
  showBackdrop,
  small,
  positionRelative,
  hideScrollBar,
  className,
  title,
}) {
  return (
    <>
      {showBackdrop && (
        <div className="modal-backdrop fade show"></div>
      )}
      <div
        className={classNames(
          "modal",
          "d-block",
          !showBackdrop ? "pe-none no-backdrop" : "",
          small ? "modal-small" : "",
          positionRelative ? "position-relative" : "",
          hideScrollBar ? "overflow-hidden" : ""
        )}
        tabIndex="-1"
        aria-modal="true"
      >
        <div
          className={classNames(
            "modal-dialog",
            "modal-dialog-centered",
            !showBackdrop ? "pe-none" : "",
            className
          )}
          role="document"
        >
          <div className="modal-content">
            {title && (
              <div className="modal-header">
                <h3 className="modal-title w-100 text-center">
                  {title}
                </h3>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
