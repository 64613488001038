import { useState } from "react";

// elements
import Alert from "../elements/Alert";
import BackButton from "../elements/BackButton";
import LeaderboardForm from "./LeaderboardForm";
import LeaderboardHelpModal from "./LeaderboardHelpModal";

// js
import { DispatchMethods } from "../../js/enums";
import {
  LoginByCodeAndCentre,
  LoginByCodeAndBookingRef,
} from "../../js/scoringService";

// reducer
import { useGameReducer } from "../../GameContext";

function Leaderboard({ game }) {
  const dispatch = useGameReducer();

  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [isUpdatingScores, setUpdatingScores] = useState(null);

  const displayModal = (toggle) => {
    setModal(toggle);
  };

  const refreshScores = () => {
    updateScores(DispatchMethods.RefreshScores);
  };

  const inputScores = () => {
    updateScores(DispatchMethods.InputScores);
  };

  const loginAPICall = (code, bookingRef, centre) => {
    if (bookingRef !== null && bookingRef !== "") {
      return LoginByCodeAndBookingRef(code, bookingRef);
    } else {
      return LoginByCodeAndCentre(code, centre);
    }
  };

  const updateScores = (dispatchMethod) => {
    setUpdatingScores(true);
    loginAPICall(game.groupNameAndCode, game.bookingRef, game.centre)
      .then((response) => {
        const data = response.data;
        if (data.statusCode !== null && data.statusCode === 0) {
          dispatch({
            type: dispatchMethod,
            game: data.result,
          });
        } else {
          setErrorMessage(data.message);
        }
        setUpdatingScores(false);
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        setUpdatingScores(false);
      });
  };

  return (
    <>
      <BackButton
        dispatchMethod={DispatchMethods.BackToSelectGame}
        text={"Exit Round"}
      />
      <LeaderboardForm
        displayModal={displayModal}
        game={game}
        inputScores={inputScores}
        isUpdatingScores={isUpdatingScores}
        refreshScores={refreshScores}
      />
      {errorMessage && <Alert text={errorMessage} />}
      {modal && <LeaderboardHelpModal displayModal={displayModal} />}
    </>
  );
}

export default Leaderboard;
