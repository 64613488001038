// elements
import Alert from "../elements/Alert";
import Button from "../elements/Button";
import InputScoreSelectHole from "./InputScoreSelectHole";
import PlayerInputScore from "../player/PlayerInputScore";

// js
import { ButtonStyle, InputType } from "../../js/enums";
import { getSelectedGame } from "../../js/scoring";

// reducer
import { useState } from "react";

function InputScoreForm({
  displayModal,
  game,
  isSubmitting,
  saveScores,
}) {
  const [modalFirstTimeDisplayed, setModalFirstTimeDisplayed] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentHoleNumber, setCurrentHoleNumber] = useState(null);
  const [holeNumberChanged, setHoleNumberChanged] = useState(null);

  const [selectedScoreList, setSelectedScoreList] = useState(null);

  const selectedGame = getSelectedGame(game);
  if (
    !modalFirstTimeDisplayed &&
    selectedGame.gameNumber === 1 &&
    selectedGame.latestHole === 0
  ) {
    setModalFirstTimeDisplayed(true);
    displayModal(true);
  }

  const setHoleNumber = (number) => {
    setCurrentHoleNumber(number);
    setHoleNumberChangedFlag(true);
  };

  const setHoleNumberChangedFlag = (flag) => {
    setHoleNumberChanged(flag);
    setErrorMessage(null);
  };

  const submitScores = () => {
    //Check for each player on the current hole whether a valid score has been entered
    //whether its via a manual selection of the dropdown list or an already inputted score
    //(if it were on a previously played hole). If any players are missing valid scores then don't
    //submit and return and error message telling the user to put in valid scores for all users
    let submittedSelectedScoreList = {};
    selectedGame.playersAndScores.forEach((p, i) => {
      const playerScoreForHole = p.holesAndScores.filter(
        (p) => p.holeNumber === holeNumber
      );

      if (selectedScoreList && selectedScoreList[p.playerNumber]) {
        submittedSelectedScoreList[p.playerNumber] =
          selectedScoreList[p.playerNumber];
      } else if (
        playerScoreForHole &&
        playerScoreForHole.length > 0 &&
        playerScoreForHole[0].enteredScoreForHole > 0
      ) {
        submittedSelectedScoreList[p.playerNumber] =
          playerScoreForHole[0].enteredScoreForHole;
      }
    });

    if (
      Object.keys(submittedSelectedScoreList).length ===
      selectedGame.playersAndScores.length
    ) {
      saveScores(holeNumber, submittedSelectedScoreList);
    } else {
      setErrorMessage("Please input valid scores for all players");
    }
  };

  const updatePlayerScore = (playerNumber, selectedScore) => {
    let updatedSelectedScoreList = selectedScoreList;
    if (
      selectedScoreList === null ||
      selectedScoreList.length === 0
    ) {
      updatedSelectedScoreList = {};
    }

    updatedSelectedScoreList[playerNumber] = selectedScore;

    setSelectedScoreList(updatedSelectedScoreList);
  };

  const holeNumber = currentHoleNumber
    ? currentHoleNumber
    : selectedGame.latestHole + 1;

  return (
    <>
      <div className="mb-2">
        <div className="container">
          <InputScoreSelectHole
            currentHoleNumber={holeNumber}
            setHoleNumber={setHoleNumber}
            numberOfHoles={selectedGame.numberOfHoles}
          />
          {holeNumber === selectedGame.numberOfHoles && (
            <h6 className="text-center">
              Note: Once you’ve played your last hole, your ball will
              be taken
            </h6>
          )}
          <Button
            isBlock={true}
            style={ButtonStyle.Link}
            className="text-center"
            text={"How does scoring work?"}
            onClick={() => displayModal(true)}
            type={InputType.Button}
          />
        </div>
        <hr />
        {selectedGame.playersAndScores
          .sort(function (a, b) {
            return a.playerNumber - b.playerNumber;
          })
          .map((p, i) => {
            return (
              <>
                <PlayerInputScore
                  currentHoleNumber={
                    currentHoleNumber
                      ? currentHoleNumber
                      : selectedGame.latestHole + 1
                  }
                  player={p}
                  holeNumberChanged={holeNumberChanged}
                  setHoleNumberChangedFlag={setHoleNumberChangedFlag}
                  updatePlayerScore={updatePlayerScore}
                  numberOfHoles={selectedGame.numberOfHoles}
                />
                <hr />
              </>
            );
          })}
        <div className="container">
          {errorMessage && <Alert text={errorMessage} />}
          <div>
            <Button
              style={ButtonStyle.Primary}
              text={"Save Scores"}
              onClick={() => submitScores()}
              type={InputType.Button}
              isBlock={true}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InputScoreForm;
