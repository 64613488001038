// elements
import Alert from "../elements/Alert";
import Button from "../elements/Button";
import Courses from "./Courses";

// js
import { ButtonStyle, DispatchMethods } from "../../js/enums";
import { getSelectedGame } from "../../js/scoring";
import { StartGame } from "../../js/scoringService";

// reducer
import { useGameReducer } from "../../GameContext";
import { useState } from "react";

function CourseForm({ game }) {
  const dispatch = useGameReducer();
  const [errorMessage, setErrorMessage] = useState(null);
  const [courseSelectedFlag, setCourseSelectedFlag] = useState(null);
  const [selectedCourse, setCourseSelected] = useState(null);

  const selectedGame = getSelectedGame(game);

  const CourseSelected = (course) => {
    setCourseSelectedFlag(true);
    setCourseSelected(course);
  };

  const SelectCourse = (gameItemId, game) => {
    const { bookingRef, centre, groupNameAndCode } = game;

    StartGame(
      gameItemId,
      groupNameAndCode,
      bookingRef,
      selectedCourse,
      centre
    )
      .then((response) => {
        const data = response.data;
        if (data.statusCode !== null && data.statusCode === 0) {
          dispatch({
            type: DispatchMethods.StartGame,
            gameItemId: gameItemId,
            gameStarted: data.result.Status,
            numberOfHoles: data.result.NumberOfHoles,
            course: selectedCourse,
            centre: centre,
          });
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
      });
  };

  return (
    <>
      <hr />
      <Courses
        centre={game.centre}
        courseSelected={(course) => CourseSelected(course)}
      />
      <div className="container">
        <Button
          className={"my-3"}
          disabled={!courseSelectedFlag}
          isBlock={true}
          onClick={() => SelectCourse(selectedGame.gameItemId, game)}
          style={ButtonStyle.Primary}
          text="Continue"
        />
        {errorMessage && <Alert text={errorMessage} />}
      </div>
    </>
  );
}

export default CourseForm;
