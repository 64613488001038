import availableJoker from "../../assets/icons/joker1.png";
import unAavailableJoker from "../../assets/icons/joker2.png";
import crossedOutJoker from "../../assets/icons/joker4.png";

import "../icons.scss";

// components
import FormInput from "../elements/FormInput";
// js
import { InputType } from "../../js/enums";
import { displayJokerUseThemOrLoseThemHighlightForSpecificPlayer } from "../../js/scoring";

function PlayerApplyJoker({
  game,
  holeNumber,
  player,
  toggleJokerForPlayer,
}) {
  let jokerWarningHighlight =
    displayJokerUseThemOrLoseThemHighlightForSpecificPlayer(
      game,
      holeNumber,
      player
    )
      ? "joker-highlight"
      : "";
  return (
    <>
      <div className="container">
        <div className={`row ${jokerWarningHighlight}`}>
          <div className="col-6 pl-3">{player.playerName}</div>
          <div className="col-3">
            <FormInput
              name={`Player${player.playerNumber}Score`}
              id={`Player${player.playerNumber}Score`}
              onChange={(checked) =>
                toggleJokerForPlayer(player, checked.target.checked)
              }
              type={InputType.Checkbox}
              className="custom-radio cursor-pointer"
              disabled={player.jokersRemaining === 0}
            />
          </div>
          <div className="col-1">
            <img
              className="icon"
              src={
                player.jokersRemaining > 0
                  ? availableJoker
                  : unAavailableJoker
              }
              alt=""
            />
          </div>
          <div className="col-1">
            <img
              className="icon"
              src={
                player.jokersRemaining > 1
                  ? holeNumber === game.numberOfHoles
                    ? crossedOutJoker
                    : availableJoker
                  : unAavailableJoker
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerApplyJoker;
