import { useState } from "react";

// elements
import Alert from "../elements/Alert";
import BackButton from "../elements/BackButton";
import InputScoreForm from "./InputScoreForm";
import InputScoreHelpModal from "./InputScoreHelpModal";

// js
import { DispatchMethods } from "../../js/enums";
import { SaveScoresAndReturnUpdatedGame } from "../../js/scoringService";

// reducer
import { useGameReducer } from "../../GameContext";

function InputScores({ game }) {
  const dispatch = useGameReducer();

  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const displayModal = (toggle) => {
    setModal(toggle);
  };

  const saveScores = (
    currentHoleNumber,
    submittedSelectedScoreList
  ) => {
    setIsSubmitting(true);
    SaveScoresAndReturnUpdatedGame(
      game.gameId,
      game.selectedGameItemId,
      currentHoleNumber,
      submittedSelectedScoreList
    )
      .then((response) => {
        const data = response.data;
        if (data.statusCode !== null && data.statusCode === 0) {
          dispatch({
            type: DispatchMethods.SaveScores,
            game: data.result,
            nextHole: currentHoleNumber + 1,
          });
        } else {
          setErrorMessage(data.message);
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <BackButton
        dispatchMethod={DispatchMethods.BackToLeaderboard}
        text={"Back"}
      />
      <InputScoreForm
        displayModal={displayModal}
        game={game}
        isSubmitting={isSubmitting}
        saveScores={saveScores}
      />
      {errorMessage && <Alert text={errorMessage} />}
      {modal && <InputScoreHelpModal displayModal={displayModal} />}
    </>
  );
}

export default InputScores;
