import React from "react";
import ReactDOM from "react-dom/client";
import GameScore from "./components/GameScore";
import { GameStateProvider } from "./GameContext";
import "./scss/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GameStateProvider>
      <div className="d-flex flex-column wrapper">
        <div className="flex-fill">
          <GameScore />
        </div>
      </div>
    </GameStateProvider>
  </React.StrictMode>
);
